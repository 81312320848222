import { useState } from 'react';
import './App.css';
import GadLogo from './gadLogo.png'

function App() {
  const [password, setPassword] = useState('');

  function DownloadButton(props) {
    const handleDownload = () => {
      console.log(props.filename);
      fetch(window.URL + "authenticate", { // Ensure this URL matches your Flask server's /authenticate endpoint
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({password: password, filename: props.filename}),
      })
      .then(response => {
          if (response.ok) {
            return response.json(); // Expecting a JSON response with the token
          } else {
              throw new Error('Unauthorized');
          }
      })
      .then(data => {
        if (data.token) {
          // Redirect the user to the download URL with the token
          window.location.href = window.URL + `download/${data.token}`;
        }
      })
      .catch(error => alert('Failed to download file. Make sure you typed the password correctly.'));
    };

    return (
      <button onClick={handleDownload}>Download {props.label}</button>
    );
  }

  return (
    <div className="App">
      <div className='text_container'>
        <div className = 'title_container'> 
          <img src = {GadLogo} style = {{'width' : '70px', 'height' : '70px'}}/> 
          <h1> GAD McCloud interest calculator </h1>
        </div>
        <p> The purpose of this calculator is to calculate the interest that should apply to payments arising from applying the McCloud remedy. This calculator should not be shared with or be relied upon by parties other than the intended user. </p>
      <p style={{ 'fontWeight': 'bold' }}>This calculator is for use by scheme administrators only, and does not cover judicial or local government pension schemes.</p>
      <p> This calculator is based on GAD’s interpretation of the relevant legislation covering interest applying to McCloud Remedy payments. Users should ensure that they are aware of the requirements made under prevailing legislation, including any amendments made in the future.
      </p> <p> Note that 15(2), 15(10), 15(12) and 15(14) of the Directions set out two possible approaches for the application of NS&I interest. The calculator adopts approach (a) i.e. interest is calculated assuming payments fell halfway through the scheme year (or part thereof).
      </p> <p> Tax is not considered in this calculator. This should be considered by the user.
      </p>
      <p>The current version of the calculator is version 3.0</p>
      <p>Users should ensure the NS&I rate assumptions in the calculator are up to date. The most recent NS&I update in this version of the calculator is 23 May 2024.</p> 
        <p style={{ 'fontWeight': 'bold' }}> Enter a password. The buttons will only work if the password is correct. </p>
        <input type="password" value={password} onChange={e => setPassword(e.target.value)} placeholder="Enter Password" />
        <div className = 'button_container_container'>
        <div className='button_container'>
          <DownloadButton
        filename = 'Interest calculator v3.0.xlsb'
        label = 'interest calculator'
        />
         <DownloadButton
        filename = 'McCloud interest calculator user guide v3.0.pdf'
        label = 'user guide'
        />
        
        </div>
        <div className = 'button_container'>
        <DownloadButton
        filename = 'McCloud interest 27 February 2024.pdf'
        label = 'guidance note (England & Wales)'
        />
        <DownloadButton
        filename = 'McCloud interest (Northern Ireland) 27 February 2024.pdf'
        label = 'guidance note (Northern Ireland)'
        />       
        </div>
        </div>
      </div>
    </div>
  );
}

export default App;
